export const API = "api";

export const CAUTHANG = "cauthang";
export const CAPSANHDON = "capsanhdon";
export const LAVOBOBANQUAY = "lavobobanquay";
export const TRUCOT = "trucot";
export const BANBEP = "banbep";
export const MATTIEN = "mattien";
export const BANGHIEUVACHDUNG = "banghieuvachdung";
export const NENBANGHEDA = "nenbangheda";
export const SPECIAL = "special";
export const GRANITE = "granite";
export const MARBLE = "marble";
